<template>
  <v-card v-if="platforms">
    <TitleBar @close="$emit('cancel')">
      Create Connection
    </TitleBar>

    <v-card-text>
      <v-form>
        <SelectTextField
          label="PLATFORM"
          v-model="$v.connection.idplatform"
          :items="platforms"
          item-value="idplatform"
          item-text="name"
        />

        <InputTextField label="CONNECTION NAME" v-model="$v.connection.name" />
        <InputTextField label="USERNAME" v-model="$v.connection.username" />
        <SecretTextField label="PASSWORD" v-model="$v.connection.password" />
        <SecretTextField label="SECRET" v-model="$v.connection.secret" />
        <InputTextField label="CLIENT ID" v-model="$v.connection.client_id" />
        <InputTextField label="URL ADDRESS" v-model="$v.connection.url" />
      </v-form>
    </v-card-text>
    <v-card-actions>
      <DismissButton @click="$emit('cancel')">Cancel</DismissButton>
      <PrimaryButton @click="createConnection">Create</PrimaryButton>
    </v-card-actions>
  </v-card>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";

export default {

  data () {
    return {
      platforms: null,
      connection: {
        idplatform: 0,
        name: null,
        username: null,
        password: null,
        secret: null,
        client_id: null,
        url: null
      },
      // wufooConnection: null,
    };
  },

  computed: {
    isWufoo() {
      return this.connection.idplatform == 1
    }
  },

  mixins: [validationMixin],
  validations: {
    connection: {
      idplatform: { required },
      name: { required },
      username: { required },
      password: { required },
      secret: { required },
      client_id: { required },
      url: { required },
    },

    // wufooConnection: {
    //   api_key: { required },
    // },
  },

  mounted() {
    this.getPlatforms()
  },

  methods: {
    // TODO: get once and store in vuex
    async getPlatforms() {
      const headers = {
        Authorization: "Bearer " + this.$cookies.get("requestToken")
      }
      try {
        const response = await this.$http.get('/api/platform', { headers })
        this.platforms = response.data
      } catch (error) {
        console.log(error)
      }
    },
    async createConnection() {

      if (this.isWufoo) {
        return
      } else {
        if (this.$v.connection.$invalid) {
          this.$v.connection.$touch()
          return
        }
      }

      //await this.$helpers.refreshAccessToken();
      const headers = {
        Authorization: "Bearer " + this.$cookies.get("requestToken")
      }
      let data = this.prepareData()
      try {
        await this.$http.post('/api/connection', data, { headers })
        this.$emit('done')
      } catch (error) {
        console.log(error)
      }
    },
    prepareData() {
      let data = {
        idplatform: this.connection.idplatform,
        name: this.connection.name,
        username: this.connection.username
      }
      if (this.isWufoo) {
        return data
      } else {
        data.password = this.connection.password
        data.secret = this.connection.secret
        data.client_id = this.connection.client_id
        data.url = this.connection.url
      }
      return data
    },
    errorMessage(fieldName) {
      return this.$v.connection[fieldName].$dirty &&
        !this.$v.connection[fieldName].required
        ? "Required"
        : null;
    }
  },
};
</script>
