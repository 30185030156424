<!--========================
Author by: Sreng Veasna
Created on: 26.09.2020
Modified:
Company: F2C V2
Description:
=========================-->
<template>
  <div>
    <!-- Breadcrumbs -->

    <v-card class="ma-4" flat>
      <TitleBar>
        Connections
        <template #right-items>
          <PrimaryButton @click="addConnection">Add New</PrimaryButton>
        </template>
      </TitleBar>
      <SectionBar>
        <template #left-items>
          <SearchTextField v-model="search" />
        </template>
      </SectionBar>
      <v-data-table
        :headers="headers"
        :items="connections"
        :search="search"
        @click:row="rowClicked"
      >
        <!-- eslint-disable-next-line -->
        <template #item.actions="{ item }">
          <v-btn icon @click.stop="confirmDelete(item.idconnection)">
            <v-icon small> mdi-delete </v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-card>

    <v-dialog max-width="400px" v-model="showHideModal">
      <v-card>
        <v-card-title>Are you sure you want to delete?</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="showHideModal = false">Discard</v-btn>
          <v-btn @click="deleteConnection" color="warning">OK</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- :key to force v-dialog to re-render -->
    <v-dialog
      max-width="600px"
      v-model="showConnectionModal"
      :key="`show-${showConnectionModal}`"
    >
      <ConnectionSheet
        :connectionId="viewConnectionId"
        @done="connectionSaved"
        @cancel="setViewConnection(null)"
      />
    </v-dialog>

    <v-dialog
      max-width="600px"
      v-model="showNewConnectionModal"
      :key="`new-${showNewConnectionModal}`"
    >
      <NewConnectionSheet
        @done="connectionAdded"
        @cancel="showNewConnectionModal = false"
      />
    </v-dialog>
  </div>
</template>

<script>

import ConnectionSheet from '../../components/Connection/ConnectionSheet.vue'
import NewConnectionSheet from '../../components/Connection/NewConnectionSheet.vue'

export default {
  name: "HomeConnection",
  components: { ConnectionSheet, NewConnectionSheet },

  data: function () {
    return {
      search: "",
      showHideModal: false,
      getIdConnection: "",
      connections: [],
      headers: [
        { value: "name", text: "Name" },
        { value: "platformName", text: "Platform" },
        {
          value: "actions",
          text: null,
          width: "40px",
          sortable: false,
          filterable: false,
        },
      ],

      showNewConnectionModal: false,

      showConnectionModal: false,
      viewConnectionId: null
    };
  },

  mounted() {
    this.getConnections();
  },
  methods: {
    async getConnections() {
      this.$store.commit("toggle", "showHideModalSpinner");

      const headers = {
        Authorization: "Bearer " + this.$cookies.get("requestToken"),
      };
      try {
        const response = await this.$http.get("/api/connection", { headers });
        this.connections = response.data
      } catch (error) {
        console.log(error);
      } finally {
        this.$store.commit("toggle", "showHideModalSpinner");
      }
    },
    addConnection() {
      this.showNewConnectionModal = true
    },
    connectionAdded() {
      this.showNewConnectionModal = false
      this.getConnections()
    },
    rowClicked(connection) {
      this.setViewConnection(connection.idconnection)
    },
    setViewConnection(connectionId) {
      this.viewConnectionId = connectionId
      this.showConnectionModal = (connectionId != null)
    },
    connectionSaved() {
      this.setViewConnection(null)
      this.getConnections()
    },
    confirmDelete(IdConnection) {
      this.showHideModal = true;
      this.getIdConnection = IdConnection;
    },
    async deleteConnection() {
      this.showHideModal = false;
      this.$store.commit("set", ["modalSpinnerText", "Deleting..."]);
      this.$store.commit("toggle", "showHideModalSpinner");

      const headers = {
        Authorization: "Bearer " + this.$cookies.get("requestToken"),
      };
      try {
        await this.$http.delete("/api/connection/" + this.getIdConnection, {
          headers,
        });
        this.getConnections();
        this.$store.commit("toggle", "showHideModalSpinner");
      } catch (error) {
        console.log(error);
        this.$store.commit("toggle", "showHideModalSpinner");
      }
    },
  },
};
</script>
